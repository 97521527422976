import React, { Component } from 'react'

import { withStyles } from '@mindz/react-hoc'
import AuthLayout from 'layouts/AuthLayout'
import styles from './index.module.css'
import { Link } from 'react-router-dom'
import { Button } from 'components/forms'
import buttonStyles from './overrides/Button.module.css'

class SignUpLayout extends Component {
    render() {
        const { children, icon, title, subtitle, description, message, sidebarDescription, contentStyles, rootStyles } = this.props

        return (
            <AuthLayout customStyles={rootStyles}>
                <AuthLayout.Header
                    render={({ cx }) => (
                        <React.Fragment>
                            <span className={cx('label')}>
                                Masz już konto?
                            </span>
                            <Link to="/logowanie">
                                <Button customStyles={buttonStyles} theme="white" size="s-size">
                                    Zaloguj się
                                </Button>
                            </Link>
                        </React.Fragment>
                    )}
                />
                <AuthLayout.Main>
                    <AuthLayout.Sidebar>
                        <h1>
                            Dlaczego warto dołączyć do Regionalny Kaufland?
                        </h1>
                        <p>
                            {sidebarDescription}
                        </p>
                    </AuthLayout.Sidebar>
                    <AuthLayout.Content
                        icon={icon}
                        title={title}
                        subtitle={subtitle}
                        description={description}
                        message={message}
                        customStyles={contentStyles}
                    >
                        {children}
                    </AuthLayout.Content>
                </AuthLayout.Main>
            </AuthLayout>
        )
    }
}

export default withStyles(styles)(SignUpLayout)
