import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import { Link } from 'react-router-dom'

import { Button } from 'components/forms'

import SignUpLayout from 'layouts/SignUpLayout'
import styles from './index.module.css'
import contentStyles from './overrides/Content.module.css'

import icon from './assets/icon-rolnik.svg'

class RegisterConfirmation extends Component {
    render() {
        return (
            <SignUpLayout
                icon={icon}
                subtitle="Rejestracja"
                title="Potwierdź e-mail"
                description="Dziękujemy za rejestrację. Prosimy o potwierdzenie e-mail, aby Twoje konto zostało aktywowane."
                sidebarDescription="Możliwość szybkiego wprowadzenia produktu na rynek."
                contentStyles={contentStyles}
            >
                <Link to="/">
                    <Button className="upper" theme="white" size="m-size">
                        Wróć do strony głównej
                    </Button>
                </Link>
            </SignUpLayout>
        )
    }
}

export default withStyles(styles)(RegisterConfirmation)
