import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import { Formik } from 'formik'

import { selectCategoryScheme } from 'scheme'
import { Button } from 'components/forms'
import { Modal } from 'components/modal'
import SnakeLoader from 'components/SnakeLoader'

import styles from './index.module.css'
import dialogStyles from './overrides/Dialog.module.css'
import buttonStyles from './overrides/Button.module.css'
import snakeLoaderStyles from './overrides/snakeLoader.module.css'

class SelectCategoryModal extends Component {
    render() {
        const { handleClose } = this.props

        return (
            <Modal.Dialog customStyles={dialogStyles}>
                <Modal.Header handleClose={handleClose}>
                    <Modal.Title>Wybierz kategorie</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.renderBody()}</Modal.Body>
            </Modal.Dialog>
        )
    }

    renderBody() {
        const {
            cx,
            categories,
            handleSubmit,
            handleClose,
            renderDropdown
        } = this.props

        if (categories.fetchStatus.isLoading) {
            return <SnakeLoader customStyles={snakeLoaderStyles} />
        }

        return (
            <Formik
                initialValues={{
                    category: '',
                    subcategory: ''
                }}
                validateOnChange={false}
                onSubmit={handleSubmit}
                validationSchema={selectCategoryScheme}
                render={({
                    isSubmitting,
                    handleSubmit,
                    values,
                    setFieldValue,
                    setValues
                }) => (
                    <form>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    {renderDropdown({
                                        name: 'category',
                                        values,
                                        handleChange: value => {
                                            setValues({
                                                category: value,
                                                subcategory: ''
                                            })
                                        },
                                        items: categories.data,
                                        dropdownProps: {
                                            headerLabel: 'Kategoria',
                                            numberVisibleElements: 6
                                        }
                                    })}
                                </div>
                            </div>
                            <div className={cx('actions')}>
                                <Button
                                    type="button"
                                    size="m-size"
                                    theme="white"
                                    customStyles={buttonStyles}
                                    className={cx('grow0')}
                                    onClick={handleClose}
                                >
                                    Anuluj
                                </Button>
                                <Button
                                    type="button"
                                    size="m-size"
                                    customStyles={buttonStyles}
                                    className={cx('grow1')}
                                    isLoading={isSubmitting}
                                    onClick={() => handleSubmit()}
                                >
                                    Dalej
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            />
        )
    }
}

export default withStyles(styles)(SelectCategoryModal)
