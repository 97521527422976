export const STATUSES = {
    DRAFT: 'draft',
    NEW: 'new',
    ACCEPTED: 'accepted',
    REJECTED: 'rejected'
}

export const STATUSES_META = {
    draft: {
        label: 'Wersja robocza',
        backgroundColor: 'rgba(255,164,84,0.2)',
        color: '#f97500'
    },
    new: {
        label: 'Oczekuje na weryfikację',
        backgroundColor: 'rgba(181,227,236,0.5)',
        color: '#0d7f95'
    },
    accepted: {
        label: 'Weryfikowane',
        backgroundColor: 'rgba(186,237,205,0.5)',
        color: '#18a34e'
    },
    rejected: {
        label: 'Weryfikacja zakończona',
        backgroundColor: 'rgba(255,64,82,0.2)',
        color: '#e6374b'
    }
}
