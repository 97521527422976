import React, { Component } from 'react'
import { Formik } from 'formik'
import { withStyles } from '@mindz/react-hoc'
import * as yup from 'yup'

import { Button, InputField, TextArea, Field, Checkbox } from 'components/forms'

import SignUpLayout from 'layouts/SignUpLayout'
import FlashMessage from 'components/FlashMessage'
import styles from './index.module.css'

import inputFieldStyles from './overrides/InputField.module.css'
import buttonStyles from './overrides/Button.module.css'
import checkboxStyles from './overrides/Checkbox.module.css'
import layoutContentStyles from './overrides/ContentRegister.module.css'
import layoutRootStyles from './overrides/AuthLayout.module.css'

import { LANDING_PAGE_HOST } from 'env'

const schema = yup.object().shape({
    email: yup.string().required('To pole jest wymagane'),
    first_name: yup.string().required('To pole jest wymagane'),
    last_name: yup.string().required('To pole jest wymagane'),
    phone: yup.string().required('To pole jest wymagane'),
    password: yup.string().required('To pole jest wymagane'),
    company: yup.object().shape({
        name: yup.string().required('To pole jest wymagane'),
        established: yup.string(),
        description: yup.string().required('To pole jest wymagane'),
    }),
    terms: yup.object().shape({
        cooperation_terms: yup.boolean().required('To pole jest wymagane'),
        regulations_and_privacy_policy: yup.boolean(),
    })
})

class RegisterPage extends Component {
    renderCooperationTerms({ values, errors, setFieldValue }) {
        const { cx } = this.props

        return (
            <div className={cx('agree')}>
                <Field
                    name="terms.cooperation_terms"
                    value={values.terms.cooperation_terms}
                    render={({ name, value }) => (
                        <Checkbox
                            id="cooperation_terms"
                            isLink={true}
                            name={name}
                            value={value}
                            error={errors[name]}
                            customStyles={checkboxStyles}
                            handleChange={() => {
                                setFieldValue(name, !value)
                            }}
                        >
                            Potwierdzam, że zapoznałam/em się z treścią{' '}
                            <a
                                href={
                                    LANDING_PAGE_HOST +
                                    '/Regionalny_Kaufland_Regulamin.pdf'
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Regulaminu Platformy „Regionalny Kaufland”
                            </a>
                            {' '}i akceptuję jego postanowienia.
                        </Checkbox>
                    )}
                />
                <br />
                <br />
                <Field
                    name="terms.cooperation_terms"
                    value={values.terms.cooperation_terms}
                    render={({ name, value }) => (
                        <span className={cx('administratorNote')}>
                            Administratorem danych osobowych jest  Kaufland Polska Markety Spółka z ograniczoną odpowiedzialnością sp. j.
                            z siedzibą we Wrocławiu.
                            Dane będą przetwarzane w celu realizacji umowy.
                            Kontakt w sprawie ochrony danych:{' '}
                            <a href="mailto: daneosobowe@kaufland.pl">
                                daneosobowe@kaufland.pl
                            </a>.
                            Więcej informacji na temat przetwarzania danych oraz
                            przysługujących Państwu praw znajdą Państwo w naszej{' '}
                            <a

                                href={
                                    LANDING_PAGE_HOST +
                                    '/Regionalny_Kaufland-Polityka_prywatnosci.pdf'
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                            Polityce Prywatności
                            </a>.
                        </span>
                    )}
                />
            </div>
        )
    }

    render() {
        const { cx, error, values, handleSubmit } = this.props

        return (
            <SignUpLayout
                title="Rejestracja"
                description="Do zgłaszania produktów na Regionalny Kaufland wymagane jest założenie konta."
                sidebarDescription="Możliwość szybkiego wprowadzenia produktu na rynek."
                message={() => {
                    return (
                        error && (
                            <FlashMessage
                                type="failure"
                                title="Wystąpiły błędy!"
                            >
                                {error}
                            </FlashMessage>
                        )
                    )
                }}
                contentStyles={layoutContentStyles}
                rootStyles={layoutRootStyles}
            >
                <div className={cx('root')}>
                    <Formik
                        initialValues={{
                            email: values.email || '',
                            first_name: values.first_name || '',
                            last_name: values.last_name || '',
                            phone: values.phone || '',
                            password: values.password || '',
                            company: {
                                name: values.company ? values.company.name : '',
                                established: values.company
                                    ? values.company.established
                                    : '',
                                description: values.company
                                    ? values.company.description
                                    : ''
                            },
                            terms: {
                                cooperation_terms: values.terms
                                    ? values.terms.cooperation_terms
                                    : false,
                                regulations_and_privacy_policy: values.terms
                                    ? values.terms
                                          .regulations_and_privacy_policy
                                    : false
                            }
                        }}
                        onSubmit={handleSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={schema}
                        render={({
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                            values,
                            errors
                        }) => {
                            return (
                                <form
                                    className={cx('form')}
                                    onSubmit={handleSubmit}
                                >
                                    {errors.error && (
                                        <div className={cx('messageFailure')}>
                                            {errors.error}
                                        </div>
                                    )}

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    placeholder=""
                                                    headerLabel="E-mail*"
                                                    name="email"
                                                    type="text"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={[cx('name'), 'row'].join(
                                                ' '
                                            )}
                                        >
                                            <div className="col">
                                                <Field
                                                    headerLabel="Imię*"
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    placeholder=""
                                                    name="first_name"
                                                    type="text"
                                                    component={InputField}
                                                />
                                            </div>
                                            <div className="col">
                                                <Field
                                                    headerLabel="Nazwisko*"
                                                    name="last_name"
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    placeholder=""
                                                    type="text"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    headerLabel="Telefon*"
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    placeholder=""
                                                    name="phone"
                                                    type="text"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    headerLabel="Hasło*"
                                                    headerSecondLabel="Minimum 8 znaków"
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    placeholder=""
                                                    name="password"
                                                    type="password"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    headerLabel="Nazwa firmy*"
                                                    placeholder=""
                                                    name="company.name"
                                                    type="text"
                                                    value={values.company.name}
                                                    component={InputField}
                                                />
                                                <p className={cx('information')}>
                                                    Jeśli nie posiadasz zarejestrowanej nazwy firmy prosimy o wpisanie imienia i nazwiska
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    headerLabel="Rok założenia firmy"
                                                    placeholder=""
                                                    name="company.established"
                                                    type="text"
                                                    component={InputField}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <Field
                                                    customStyles={
                                                        inputFieldStyles
                                                    }
                                                    headerLabel="Profil / historia działalności*"
                                                    placeholder="Opisz profil i/lub historię swojej firmy"
                                                    name="company.description"
                                                    type="text"
                                                    component={TextArea}
                                                />
                                            </div>
                                        </div>

                                        <div className={cx('row')}>
                                            <div className={cx('col')}>
                                                {this.renderCooperationTerms({
                                                    values,
                                                    errors,
                                                    setFieldValue
                                                })}
                                            </div>
                                        </div>

                                        <div className={cx('actions')}>
                                            <div
                                                className={cx('actionsLeft')}
                                            />
                                            <div
                                                className={cx('actionsCenter')}
                                            >
                                                <Button
                                                    type="submit"
                                                    size="m-size"
                                                    customStyles={buttonStyles}
                                                    isLoading={isSubmitting}
                                                >
                                                    Zarejestruj
                                                </Button>
                                            </div>
                                            <div className={cx('actionsRight')}>

                                            </div>
                                        </div>

                                        <div
                                            className={cx(
                                                'nextStepDescription'
                                            )}
                                        >
                                            Po zarejestrowaniu otrzymasz e-mail
                                            z potwierdzeniem rejestracji.
                                        </div>
                                    </div>
                                </form>
                            )
                        }}
                    />
                </div>
            </SignUpLayout>
        )
    }
}

export default withStyles(styles)(RegisterPage)
