import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import idx from 'idx'

import * as authActions from 'actions/auth'
import * as flashMessagesActions from 'actions/flashMessages'

import SnakeLoader from 'components/SnakeLoader'

import styles from './index.module.css'

class AccountActivate extends Component {
    state = {
        isLoading: false,
        isLoaded: false,
        isError: false,
        errors: null
    }

    getToken() {
        return this.props.match.params.token
    }

    componentDidMount() {
        const { actions } = this.props

        const token = this.getToken()

        this.setState({ isLoading: true })

        actions.registerToken(token).then(
            res => {
                const name = idx(res, _ => _.data.userName)

                actions.addFlashMessage({
                    type: 'success',
                    title: `Witaj${name ? ' ' + name : ''},`,
                    content:
                        'Twoja rejestracja w Regionalny Kaufland przebiegła pomyślnie. <br /> Dziękujemy!'
                })

                this.setState({
                    isLoading: false,
                    isLoaded: true,
                    isError: false
                })
            },
            responseWithError => {
                actions.addFlashMessage({
                    type: 'failure',
                    title: 'Niepoprawny token'
                })

                this.setState({
                    isLoading: false,
                    isLoaded: false,
                    isError: true
                })

                if (responseWithError.status === 422) {
                    responseWithError.json().then(json => {
                        return this.setState({ error: json.errors })
                    })
                }
            }
        )
    }

    render() {
        const { cx } = this.props
        const { isLoading, isLoaded, isError } = this.state

        if (!this.getToken()) {
            return <Redirect to="/" />
        }

        if (isLoaded || isError) {
            return <Redirect to="/logowanie" />
        }

        if (isLoading) {
            return (
                <div className={cx('root')}>
                    <div className={cx('loader')}>
                        <SnakeLoader />
                    </div>
                </div>
            )
        }

        return null
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            { ...authActions, ...flashMessagesActions },
            dispatch
        )
    }
}

const enhance = compose(
    connect(
        null,
        mapDispatchToProps
    ),
    withStyles(styles)
)

export default enhance(AccountActivate)
