import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'

import { Button } from 'components/forms'

import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

class NotFound extends Component {
    render() {
        const { cx, history } = this.props
        return (
            <div className={cx('page-not-found')}>
                <img src="/assets/icons/rolnik-404.svg" alt="Strony nie znaleziono" />
                <h1>404</h1>
                <p>Strona, której szukasz, nie istnieje albo link jest niepoprawny.</p>
                <Button
                    theme="white"
                    size="m-size"
                    onClick={() => history.push('/')}
                    customStyles={buttonStyles}
                >
                    WRÓĆ DO DASHBOARDU
                </Button>
            </div>
        )
    }
}

export default withStyles(styles)(NotFound)
