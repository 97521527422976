import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import { Link } from 'react-router-dom'

import { Button } from 'components/forms'
import { STATUSES, STATUSES_META } from 'constants/product'

import styles from './index.module.css'
import buttonStyles from './overrides/Button.module.css'

import IconInside from 'assets/icons/arrow-inside.module.svg'
import IconArrow from 'assets/arrow_down.module.svg'

class ProductStatus extends Component {
    render() {
        const {
            cx,
            isManufacturer,
            isCollapsed,
            status,
            handleToggle
        } = this.props

        const statusMeta = STATUSES_META[this.props.status]

        return (
            <div className={cx('summary')}>
                <section
                    className={cx('root', {
                        isManufacturer,
                        isSalesman: !isManufacturer,
                        isDraft: STATUSES.DRAFT === status,
                        isNew: STATUSES.NEW === status,
                        isAccepted: STATUSES.ACCEPTED === status,
                        isRejected: STATUSES.REJECTED === status,
                        isCollapsed
                    })}
                >
                    <header
                        className={cx('header')}
                        style={{ backgroundColor: statusMeta.backgroundColor }}
                    >
                        Status zgłoszenia:
                        <h2 style={{ color: statusMeta.color }}>
                            {statusMeta.label}
                        </h2>
                    </header>
                    <section className={cx('content')}>
                        <div className={cx('mobile')}>
                            <strong>Status zgłoszenia:</strong>
                            <p
                                style={{
                                    color: statusMeta.color,
                                    backgroundColor: statusMeta.backgroundColor
                                }}
                            >
                                {statusMeta.label}
                            </p>
                            <span
                                onClick={handleToggle}
                                className={cx('toggle')}
                            >
                                <span>{isCollapsed ? 'Rozwiń' : 'Zwiń'}</span>
                                <IconArrow />
                            </span>
                        </div>
                        <div className={cx('holder')}>
                            {this.renderContent()}
                        </div>
                    </section>
                </section>
            </div>
        )
    }

    renderContent() {
        const {
            cx,
            product,
            status,
            isManufacturer,
            isSalesman,
            toggleChangeStatusModal
        } = this.props

        if (isManufacturer) {
            if (STATUSES.DRAFT === status) {
                return (
                    <div>
                        <h3>Uwaga!</h3>
                        <p>
                            Sprawdź poprawność danych, zanim wyślesz zgłoszenie
                            – jego edycja po wysłaniu nie będzie możliwa.
                        </p>
                        <Link to={'/zgloszenia/' + product.id + '/edytuj'}>
                            <IconInside />
                            Edytuj zgłoszenie
                        </Link>
                        <hr />
                        <Button
                            onClick={() => toggleChangeStatusModal('new')}
                            customStyles={buttonStyles}
                            className="upper"
                            size="m-size"
                        >
                            Wyślij
                        </Button>
                    </div>
                )
            }

            if (STATUSES.NEW === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/rolnik-wait.svg"
                                alt="Dziękujemy"
                            />
                        </div>
                        <h3>Dziękujemy</h3>
                        <p>
                            Zgłoszenie zostało wysłane i oczekuje <br/>na weryfikację
                        </p>
                    </div>
                )
            }

            if (STATUSES.ACCEPTED === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/rolnik-accepted.svg"
                                alt="Zgłoszony produkt przeszedł wstępną weryfikację"
                            />
                        </div>
                        <h3>Trwa weryfikacja zgłoszonego produktu</h3>
                    </div>
                )
            }

            if (STATUSES.REJECTED === status) {
                return (
                    <div>
                        <div className={cx('icon')}>
                            <img
                                src="/assets/icons/rolnik-remove.svg"
                                alt="Produkt nie spełnia wymogów"
                            />
                        </div>
                        <h3>Brak akceptacji</h3>
                    </div>
                )
            }

            return null
        }

        if (STATUSES.NEW === status) {
            return (
                <div>
                    <h3>Jaka decyzja?</h3>
                    <p>
                        Jeśli zgłaszany produkt spełnia wymogi, to przyjmij
                        zgłoszenie do weryfikacji. W przeciwnym razie – odrzuć
                        je.
                    </p>
                    <div className={cx('actions')}>
                        <Button
                            onClick={() => toggleChangeStatusModal('rejected')}
                            customStyles={buttonStyles}
                            className={[cx('button'), 'wide', 'upper'].join(
                                ' '
                            )}
                            disabled={!isSalesman}
                            theme="white"
                            size="l-size"
                        >
                            Odrzuć
                        </Button>

                        <Button
                            onClick={() => toggleChangeStatusModal('accepted')}
                            customStyles={buttonStyles}
                            className={[cx('button'), 'wide', 'upper'].join(
                                ' '
                            )}
                            disabled={!isSalesman}
                            size="l-size"
                        >
                            Przyjmij
                        </Button>
                    </div>
                </div>
            )
        }

        if (STATUSES.ACCEPTED === status) {
            return (
                <div>
                    <div className={cx('icon')}>
                        <img
                            src="/assets/icons/rolnik-accepted.svg"
                            alt="Zgłoszenie zweryfikowane"
                        />
                    </div>
                    <h3>W trakcie weryfikacji</h3>
                    <p>
                        Skontaktuj się z osobą zgłaszającą produkt w celu
                        ustalenia dalszych kroków.
                    </p>
                    <div className={cx('actions')}>
                        <Link to={'/firmy/' + product.manufacturer.company.id}>
                            <Button
                                customStyles={buttonStyles}
                                theme="white"
                                className={cx('button', 'button-contact', 'wide')}
                                size="m-size"
                            >
                                Kontakt do producenta
                            </Button>
                        </Link>
                        <Button
                            onClick={() => toggleChangeStatusModal('rejected')}
                            customStyles={buttonStyles}
                            className={cx('button', 'button-reject', 'wide')}
                            disabled={!isSalesman}
                            theme="white"
                            size="m-size"
                        >
                            Odrzuć
                        </Button>
                    </div>
                </div>
            )
        }

        if (STATUSES.REJECTED === status) {
            return (
                <div>
                    <div className={cx('icon')}>
                        <img
                            src="/assets/icons/rolnik-remove.svg"
                            alt="Produkt nie spełnia wymogów"
                        />
                    </div>
                    <h3>Weryfikacja zakończona</h3>
                    <p>Brak akceptacji</p>
                </div>
            )
        }
    }
}

export default withStyles(styles)(ProductStatus)
