import React from 'react'
import { Button as ButtonBase } from '@mindz/react-admin-ui'

import { withStyles } from '@mindz/react-hoc'

import styles from './index.module.css'

export default withStyles(styles)(({ children, cx, className, ...props }) => {
    className = className ? className.split(' ') : []

    return (
        <ButtonBase.Rolnik loadingIcon="round-arrow" className={cx(...className)} cx={cx} {...props}>
            {children}
        </ButtonBase.Rolnik>
    )
})
