import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import * as actions from 'actions/auth'

import RegisterPage from './RegisterPage'
import RegisterConfirmation from './RegisterConfirmation'

class RegisterPageContainer extends Component {
    state = {
        success: false,
        error: null,
        values: {}
    }

    render() {
        const { user } = this.props
        const { success, values } = this.state

        if (user.loggedIn) {
            return <Redirect to="/" />
        }

        if (success) {
            return <RegisterConfirmation />
        }

        return (
            <RegisterPage
                error={this.state.error}
                values={values}
                handleSubmit={this.handleSubmit}
            />
        )
    }

    handleSubmit = (values, formikActions) => {
        const { actions } = this.props

        formikActions.setSubmitting(true)

        actions.register({ values, validate: true }).then(() => {
            this.setState(
                prevState => ({
                    values: {
                        ...prevState.values,
                        ...values
                    }
                }),
                () => {
                    return actions
                        .register({
                            values: this.state.values,
                            validate: false
                        })
                        .then(
                            () => {
                                this.setState({ success: true })
                            },
                            responseWithError => {
                                formikActions.setSubmitting(false)

                                if (responseWithError.status === 422) {
                                    return this.setState(
                                        {
                                            currentStep: 1
                                        },
                                        () => {
                                            responseWithError
                                                .json()
                                                .then(json => {
                                                    this.setState({
                                                        error:
                                                            'Formularz zawiera błędy, sprawdź go jeszcze raz'
                                                    })
                                                })
                                        }
                                    )
                                }

                                formikActions.setErrors({
                                    error: responseWithError.statusText
                                })
                            }
                        )
                }
            )
        }, this.handleError(formikActions))
    }

    handleError(formikActions) {
        return responseWithError => {
            formikActions.setSubmitting(false)

            if (responseWithError.status === 422) {
                return responseWithError.json().then(json => {
                    if (json.errors) {
                        return formikActions.setErrors(json.errors)
                    }

                    if (json.message) {
                        return formikActions.setErrors({
                            error: json.message
                        })
                    }
                })
            }

            formikActions.setErrors({
                error: responseWithError.statusText
            })
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.local.user
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterPageContainer)
