import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { LANDING_PAGE_HOST } from 'env'
import { withStyles } from '@mindz/react-hoc'
import AuthLayout from 'layouts/AuthLayout'
import styles from './index.module.css'
import { Button } from 'components/forms'

import contentStyles from './overrides/Content.module.css'
import buttonStyles from './overrides/Button.module.css'

class SignInLayout extends Component {
    render() {
        const { children, title, subtitle, description, message } = this.props

        return (
            <AuthLayout>
                <AuthLayout.Header
                    render={({ cx }) => (
                        <React.Fragment>
                            <span className={cx('label')}>
                                Nie masz jeszcze konta?
                            </span>
                            <Link to="/rejestracja">
                                <Button
                                    customStyles={buttonStyles}
                                    theme="white"
                                    size="s-size"
                                >
                                    Zarejestruj się
                                </Button>
                            </Link>
                        </React.Fragment>
                    )}
                />
                <AuthLayout.Main>
                    <AuthLayout.Sidebar>
                        <h1>Jedna prosta platforma dla partnerów Kaufland</h1>
                        <p>
                            Razem zwiększamy zasięg i sprzedaż Twojego produktu.
                        </p>
                        <a href={LANDING_PAGE_HOST}>
                            <Button
                                customStyles={buttonStyles}
                                theme="white"
                                size="s-size"
                            >
                                Dowiedz się więcej
                            </Button>
                        </a>
                    </AuthLayout.Sidebar>
                    <AuthLayout.Content
                        title={title}
                        subtitle={subtitle}
                        description={description}
                        message={message}
                        customStyles={contentStyles}
                    >
                        {children}
                    </AuthLayout.Content>
                </AuthLayout.Main>
            </AuthLayout>
        )
    }
}

export default withStyles(styles)(SignInLayout)
