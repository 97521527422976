import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@mindz/react-hoc'
import idx from 'idx'

import Notifications from './components/Notifications'
import styles from './index.module.css'

import BurgerIcon from 'assets/burger.module.svg'
import ArrowDownIcon from 'assets/arrow_down.module.svg'
import ArrowLeftIcon from 'assets/arrow_left.module.svg'
import UserIcon from 'assets/user.module.svg'
import UsersIcon from 'assets/users.module.svg'
import LogoutIcon from 'assets/logout.module.svg'

class Header extends Component {
    render() {
        const {
            cx,
            user,
            shortcuts,
            isManufacturer,
            isSalesman,
            isBusiness,
            isAdmin,
            isShownDropdown,
            visibleNotifications,
            active,
            toggleNotifications,
            toggleDropdown,
            setActiveShortcut,
            refRoot
        } = this.props

        return (
            <div
                className={cx('root', {
                    shown: isShownDropdown,
                    visibleNotifications
                })}
                ref={refRoot}
            >
                <div className={cx('burger')} onClick={toggleDropdown}>
                    <BurgerIcon />
                </div>

                <Link to="/" className={cx('logoLink')}>
                    <img src="/assets/logo-regionalny-kaufland.png" alt="Regionalny Kaufland" />
                </Link>
                <div className={cx('shortcuts')}>
                    {shortcuts.map((shortcut, index) => {
                        const Icon = shortcut.icon
                        return (
                            <Link
                                className={cx({
                                    active:
                                        shortcut.link === active ||
                                        ('/zgloszenia' === shortcut.link &&
                                            !(isBusiness || isAdmin) &&
                                            active === '/')
                                })}
                                key={index}
                                to={shortcut.link}
                                onClick={() => setActiveShortcut(shortcut.link)}
                            >
                                <Icon />
                                {shortcut.name}
                            </Link>
                        )
                    })}
                </div>
                <div className={cx('user')}>
                    {(isManufacturer || isSalesman) && (
                        <Notifications
                            cx={cx}
                            toggleNotifications={toggleNotifications}
                        />
                    )}

                    <div className={cx('profile')} onClick={toggleDropdown}>
                        <div className={cx('return')}>
                            <ArrowLeftIcon className={cx('arrowBack')} /> Wróć
                        </div>
                        <div className={cx('details')}>
                            <div className={cx('photo')}>
                                {this.renderInitial()}
                            </div>
                            <ul>
                                <li className={cx('name')}>
                                    {user.data.first_name} {user.data.last_name}
                                </li>
                                <li className={cx('type')}>
                                    {this.renderUserDescription()}
                                </li>
                            </ul>
                        </div>
                        <ArrowDownIcon className={cx('arrow')} />
                        <div className={cx('dropdown')}>
                            <Link className={cx('option')} to="/moje-konto">
                                <UserIcon /> Moje konto
                            </Link>
                            {isAdmin && (
                                <div className={cx('section')}>
                                    <Link
                                        className={cx('option')}
                                        to="/uzytkownicy"
                                    >
                                        <UsersIcon /> Użytkownicy
                                    </Link>
                                </div>
                            )}
                            <Link className={cx('option')} to="/wyloguj">
                                <LogoutIcon /> Wyloguj
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderUserDescription() {
        const {
            user,
            isManufacturer,
            isSalesman,
            isAdmin,
            isBusiness
        } = this.props

        if (isManufacturer) {
            return user.data.company.name
        }

        if (isSalesman) {
            return 'Kupiec Kaufland'
        }

        if (isBusiness) {
            return 'Biznes Kaufland'
        }

        if (isAdmin) {
            return 'Admin Kaufland'
        }
    }

    renderInitial() {
        const { user } = this.props

        const first_name = idx(user, _ => _.data.first_name)
        const last_name = idx(user, _ => _.data.last_name)

        let initial = ''

        if (first_name) {
            initial += first_name[0]
        }

        if (last_name) {
            initial += last_name[0]
        }

        return <span>{initial}</span>
    }
}

export default withStyles(styles)(Header)
