import React, { Component } from 'react'
import { withStyles } from '@mindz/react-hoc'
import { Formik } from 'formik'
import { Modal } from 'components/modal'
import SnakeLoader from 'components/SnakeLoader'
import { selectCategoryScheme } from 'scheme'
import { Button } from 'components/forms'

import SlateIcon from 'assets/icons/add-product-rolnik.module.svg'
import styles from './index.module.css'
import modalStyles from './overrides/modal.module.css'
import buttonStyles from './overrides/Button.module.css'
import snakeLoaderStyles from './overrides/snakeLoader.module.css'

class SelectCategoryModal extends Component {
    render() {
        const {
            isOpen,
            handleClose,
            cx,
            categories,
            handleSubmit,
            renderDropdown
        } = this.props

        return (
            <Modal isOpen={isOpen} handleClose={handleClose}>
                <Modal.Dialog customStyles={modalStyles}>
                    <Modal.Header handleClose={handleClose}>
                        <div className={cx('image')}>
                            <SlateIcon />
                        </div>
                        <Modal.Title>Wybierz kategorie produktu</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!categories.fetchStatus.isLoaded ? (
                            <SnakeLoader customStyles={snakeLoaderStyles} />
                        ) : (
                            <Formik
                                initialValues={{
                                    category: '',
                                    subcategory: ''
                                }}
                                validateOnChange={false}
                                onSubmit={handleSubmit}
                                validationSchema={selectCategoryScheme}
                                render={({
                                    isSubmitting,
                                    handleSubmit,
                                    values,
                                    setFieldValue,
                                    setValues,
                                    errors
                                }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="container-fluid">
                                            <div className={cx('row')}>
                                                <div className="col">
                                                    {renderDropdown({
                                                        name: 'category',
                                                        values,
                                                        handleChange: value => {
                                                            setValues({
                                                                category: value,
                                                                subcategory: ''
                                                            })
                                                        },
                                                        items: categories.data,
                                                        dropdownProps: {
                                                            headerLabel:
                                                                'Kategoria',
                                                            numberVisibleElements: 6
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            <div className={cx('actions')}>
                                                <Button
                                                    type="button"
                                                    size="m-size"
                                                    theme="white"
                                                    customStyles={buttonStyles}
                                                    className={cx('grow0')}
                                                    onClick={handleClose}
                                                >
                                                    Anuluj
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    size="m-size"
                                                    customStyles={buttonStyles}
                                                    className={cx('grow1')}
                                                    isLoading={isSubmitting}
                                                >
                                                    Dalej
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        )}
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        )
    }
}

export default withStyles(styles)(SelectCategoryModal)
