import React, { Component, Fragment } from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import idx from 'idx'
import { withStyles } from '@mindz/react-hoc'
import { UploadGallery } from '@mindz/react-admin-ui'

import { API } from 'env'
import {
    InputField,
    RadioGroup,
    RadioInput,
    TextArea,
    Dropdown,
    Field
} from 'components/forms'
import { Header, Actions, Section } from './components'

import IconArrow from 'assets/icons/arrow.module.svg'
import IconError from 'assets/icons/error.module.svg'
import IconErrorSmall from 'assets/icons/error-small.module.svg'
import IconClose from 'assets/icons/add.module.svg'

import styles from './index.module.css'
import brandNameStyles from './overrides/BrandName.module.css'
import galleryStyles from './overrides/Gallery.module.css'
import tileStyles from './overrides/Tile.module.css'
import actionsStyles from './overrides/Actions.module.css'
import nutritionInputStyles from './overrides/NutritionInput.module.css'
import gallerySectionStyles from './overrides/GallerySection.module.css'
import weightInputStyles from './overrides/WeightInput.module.css'

class ProductCreatePage extends Component {
    componentDidMount() {
        document
            .getElementsByTagName('BODY')[0]
            .classList.add('pageProduct', 'formEdit')
    }

    componentWillUnmount() {
        document
            .getElementsByTagName('BODY')[0]
            .classList.remove('pageProduct', 'formEdit')
    }

    isFood() {
        const { product } = this.props

        return !!product.data.category.food
    }

    render() {
        const { cx } = this.props

        return (
            <div className={cx('container')}>
                <div className={cx('goBack-container')}>
                    <Link to="/zgloszenia" className={cx('goBack')}>
                        <IconArrow />
                        <span>Wróć do zgłoszeń</span>
                    </Link>
                </div>
                <h2>Formularz zgłoszeniowy</h2>
                <div className={cx('root')}>{this.renderForm()}</div>
            </div>
        )
    }

    renderForm() {
        const {
            handleSubmit,
            handleOnChange,
            cx,
            initialValues,
            errors,
            isErrorNotification,
            hideErrorNotification,
            handleDisableValidation,
            product,
            isValidating
        } = this.props

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validate={handleOnChange}
                validateOnChange={true}
                validateOnBlur={false}
                render={({
                    handleSubmit,
                    isSubmitting,
                    values,
                    setFieldValue
                }) => {
                    return (
                        <Fragment>
                            <Header
                                category={
                                    product &&
                                    product.data &&
                                    product.data.category &&
                                    product.data.category.name
                                }
                                subcategory={
                                    product &&
                                    product.data &&
                                    product.data.subcategory &&
                                    product.data.subcategory.name
                                }
                            />
                            {isErrorNotification && (
                                <div
                                    className={cx('error-notification')}
                                    onClick={hideErrorNotification}
                                >
                                    <IconError />
                                    <p>Formularz nie może zostać wysłany</p>
                                    <p>Wypełnij wszystkie obowiązkowe pola.</p>
                                    <IconClose />
                                </div>
                            )}
                            <form
                                className={cx('form')}
                                onSubmit={handleSubmit}
                            >
                                {errors.error && (
                                    <div className={cx('error')}>
                                        {errors.error}
                                    </div>
                                )}

                                {this.renderDescriptionSection({
                                    setFieldValue,
                                    values,
                                    errors
                                })}

                                {this.renderGallerySection({
                                    errors
                                })}

                                {this.renderInformationsSection({
                                    setFieldValue,
                                    values,
                                    errors
                                })}

                                <Actions
                                    productId={idx(product, _ => _.data.id)}
                                    isLoading={isSubmitting}
                                    handleDisableValidation={
                                        handleDisableValidation
                                    }
                                    isValidating={isValidating}
                                />
                            </form>
                        </Fragment>
                    )
                }}
            />
        )
    }

    renderDescriptionSection({ setFieldValue, values, errors }) {
        const { cx, getFormikProps, handleLastYearsList } = this.props
        const groupName = 'product'

        return (
            <Section title="Informacje o produkcie">
                <Field
                    {...getFormikProps({ groupName, fieldName: 'name' })}
                    type="text"
                    component={InputField}
                />
                <div className={cx('line-cover')}>
                    <p>Czy produkt jest dostępny na rynku?</p>
                    {this.renderRadioGroup({
                        groupName,
                        fieldName: 'available_on_market',
                        setFieldValue,
                        values,
                        errors
                    })}
                    {values['product|available_on_market'] === '1' &&
                        this.renderDropdown({
                            groupName,
                            fieldName: 'available_on_market_from',
                            setFieldValue,
                            values,
                            items: handleLastYearsList(9),
                            errors
                        })}
                </div>
                <span style={{ clear: 'both', display: 'block' }} />
                <div className={cx('line-cover')}>
                    <p>Czy produkt jest zarejestrowany jako marka?</p>
                    {this.renderRadioGroup({
                        groupName,
                        fieldName: 'registered_as_brand',
                        setFieldValue,
                        values,
                        errors
                    })}
                    {values['product|registered_as_brand'] === '1' && (
                        <Field
                            {...getFormikProps({
                                groupName,
                                fieldName: 'brand_name'
                            })}
                            type="text"
                            component={InputField}
                            customStyles={brandNameStyles}
                        />
                    )}
                </div>
                <span style={{ clear: 'both', display: 'block' }} />

                <Section.Divider />

                <Field
                    {...getFormikProps({ groupName, fieldName: 'description' })}
                    component={TextArea}
                />
                {this.isFood() && (
                    <Field
                        {...getFormikProps({
                            groupName,
                            fieldName: 'components'
                        })}
                        component={TextArea}
                    />
                )}
                {!this.isFood() && (
                    <div
                        style={{ height: 0, width: 0, margin: 0, padding: 0 }}
                    />
                )}

                <Section.Divider />

                <Field
                    {...getFormikProps({ groupName, fieldName: 'netto_price' })}
                    type="text"
                    component={InputField}
                    unit="PLN / szt."
                    replaceDot={true}
                    pattern="[0-9]+([\.,][0-9]+)?"
                />
            </Section>
        )
    }

    renderGallerySection({ errors }) {
        const {
            cx,
            handleUploadSuccess,
            handleUploadReject,
            handleRemove,
            handleSetMain,
            product: {
                data: { id }
            },
            user: {
                currentUser: { token }
            },
            images,
            getFormikFieldName
        } = this.props

        return (
            <Section
                title="Zdjęcia produktu i opakowania"
                description="Dodaj min. 3 zdjęcia pokazujące Twój produkt z różnych stron oraz zdjęcie opakowania jednostkowego i zbiorczego. Akceptowane formaty plików: JPG, PNG, GIF."
                customStyles={gallerySectionStyles}
            >
                {errors &&
                    errors['product|images'] &&
                    errors['product|images'][0].length > 0 && (
                        <div className={cx('gallery-error')}>
                            <IconErrorSmall />
                            <p>
                                {
                                    errors[
                                        getFormikFieldName({
                                            groupName: 'product',
                                            fieldName: 'images'
                                        })
                                    ]
                                }
                            </p>
                        </div>
                    )}
                <UploadGallery
                    isDropzoneFirst={false}
                    endpoint={API.baseUrl + `/products/${id}/images`}
                    headers={{
                        ...API.authorizationHeaders,
                        'X-Authorization': token
                    }}
                    handleUploadSuccess={state => handleUploadSuccess(state)}
                    handleUploadReject={handleUploadReject}
                    customStyles={galleryStyles}
                    isDraggable={false}
                    isMultipleFiles={true}
                    fileFieldName="image"
                    acceptFiles='image/jpeg,image/png,image/gif'
                >
                    {images.map(image => {
                        return (
                            <UploadGallery.Tile
                                key={image.path}
                                url={image.path + '?width=168&height=168'}
                                isMainTile={!!image.main}
                                customStyles={tileStyles}
                                ActionsComponent={props => (
                                    <UploadGallery.Actions
                                        {...props}
                                        customStyles={actionsStyles}
                                        handleRemove={() =>
                                            handleRemove({
                                                id,
                                                imageId: image.id
                                            })
                                        }
                                        handleSetMain={() =>
                                            handleSetMain({
                                                id,
                                                imageId: image.id
                                            })
                                        }
                                    />
                                )}
                            >
                            </UploadGallery.Tile>
                        )
                    })}
                </UploadGallery>
            </Section>
        )
    }

    renderNutritionSection() {
        const { cx, getFormikProps } = this.props
        const groupName = 'nutritions'
        if (!this.isFood()) {
            return (
                <section
                    style={{ height: 0, width: 0, margin: 0, padding: 0 }}
                />
            )
        }

        return (
            <Section title="Wartość odżywcza w 100g">
                <Field
                    {...getFormikProps({
                        groupName,
                        fieldName: 'energy_value'
                    })}
                    type="number"
                    component={InputField}
                    step="0.01"
                />

                <Section.Divider />

                <div className={cx('line-cover')}>
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'fat' })}
                        type="number"
                        component={InputField}
                        customStyles={nutritionInputStyles}
                        step="0.01"
                    />

                    <Field
                        {...getFormikProps({
                            groupName,
                            fieldName: 'saturated_fat'
                        })}
                        type="number"
                        component={InputField}
                        customStyles={nutritionInputStyles}
                        step="0.01"
                    />
                </div>

                <div className={cx('line-cover')}>
                    <Field
                        {...getFormikProps({
                            groupName,
                            fieldName: 'carbohydrate'
                        })}
                        type="number"
                        component={InputField}
                        customStyles={nutritionInputStyles}
                        step="0.01"
                    />
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'sugar' })}
                        type="number"
                        component={InputField}
                        customStyles={nutritionInputStyles}
                        step="0.01"
                    />
                </div>
                <Field
                    {...getFormikProps({ groupName, fieldName: 'roughage' })}
                    type="number"
                    component={InputField}
                    step="0.01"
                />
                <Field
                    {...getFormikProps({ groupName, fieldName: 'protein' })}
                    type="number"
                    component={InputField}
                    step="0.01"
                />
                <Field
                    {...getFormikProps({ groupName, fieldName: 'salt' })}
                    type="number"
                    component={InputField}
                    step="0.01"
                />
            </Section>
        )
    }

    renderWrappingSection({ setFieldValue, values, errors }) {
        const { cx, getFormikProps, dictionaries } = this.props
        const groupName = 'wrapping'

        return (
            <Section
                title="Opakowanie jednostkowe"
                description="Podaj cechy opakowania Twojego produktu"
            >
                <div className={cx('line-cover')}>
                    {this.renderDropdown({
                        groupName,
                        fieldName: 'kind',
                        setFieldValue,
                        values,
                        items: idx(dictionaries, _ => _.wrappings.data) || [],
                        errors
                    })}

                    <Field
                        {...getFormikProps({ groupName, fieldName: 'value' })}
                        type="text"
                        component={InputField}
                        customStyles={weightInputStyles}
                        step="0.01"
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />

                    {this.renderDropdown({
                        groupName,
                        fieldName: 'unit',
                        setFieldValue,
                        values,
                        items: idx(dictionaries, _ => _.units.data) || [],
                        errors
                    })}
                </div>
                <Section.Divider />
                <h4>Wymiary produktu</h4> <h5>(opcjonalnie)</h5>
                <div>
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'length' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'width' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'height' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                </div>
            </Section>
        )
    }

    renderCollectiveWrappingSection({ setFieldValue, values, errors }) {
        const { cx, getFormikProps, dictionaries } = this.props
        const groupName = 'collective_wrapping'

        return (
            <Section title="Opakowanie zbiorcze " description="(opcjonalnie)">
                <div className={cx('line-cover')}>
                    {this.renderDropdown({
                        groupName,
                        fieldName: 'kind',
                        setFieldValue,
                        values,
                        items:
                            idx(
                                dictionaries,
                                _ => _.collectiveWrappings.data
                            ) || [],
                        errors
                    })}

                    <Field
                        {...getFormikProps({
                            groupName,
                            fieldName: 'product_count'
                        })}
                        type="number"
                        component={InputField}
                    />

                    <Field
                        {...getFormikProps({ groupName, fieldName: 'weight' })}
                        type="number"
                        component={InputField}
                        unit="kg"
                        step="0.01"
                    />
                </div>

                <Section.Divider />

                <div>
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'length' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'width' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                    <Field
                        {...getFormikProps({ groupName, fieldName: 'height' })}
                        type="text"
                        component={InputField}
                        replaceDot={true}
                        pattern="[0-9]+([\.,][0-9]+)?"
                    />
                </div>
            </Section>
        )
    }

    renderInformationsSection({ setFieldValue, values, errors }) {
        const { getFormikProps, handleLastYearsList } = this.props
        const groupName = 'informations'

        return (
            <Section title="Dodatkowe informacje">
                {this.renderDropdown({
                    groupName,
                    fieldName: 'created_since',
                    setFieldValue,
                    values,
                    items: handleLastYearsList(9),
                    errors
                })}
                <Field
                        {...getFormikProps({ groupName, fieldName: 'city' })}
                        type="text"
                        component={InputField}
                    />
                <span />
            </Section>
        )
    }

    renderRadioGroup({ groupName, fieldName, values, setFieldValue, errors }) {
        const { getFormikProps, getFormikFieldName } = this.props
        const name = getFormikFieldName({ groupName, fieldName })

        const value = values[name] === '1' ? '1' : '0'
        return (
            <RadioGroup
                {...getFormikProps({
                    groupName,
                    fieldName
                })}
                error={
                    errors[name]
                        ? Array.isArray(errors[name])
                            ? errors[name][0]
                            : errors[name]
                        : null
                }
                handleChange={value => {
                    setFieldValue(name, value)

                    if (fieldName === 'available_on_market' && value === '0') {
                        setFieldValue(
                            getFormikFieldName({
                                groupName,
                                fieldName: 'available_on_market_from'
                            }),
                            ''
                        )
                    }

                    if (fieldName === 'registered_as_brand' && value === '0') {
                        setFieldValue(
                            getFormikFieldName({
                                groupName,
                                fieldName: 'brand_name'
                            }),
                            ''
                        )
                    }
                }}
                value={value}
            >
                <RadioInput value="1" text="Tak" />
                <RadioInput value="0" text="Nie" />
            </RadioGroup>
        )
    }

    renderDropdown({
        groupName,
        fieldName,
        setFieldValue,
        values,
        items,
        errors
    }) {
        const { getFormikProps, getFormikFieldName } = this.props

        const name = getFormikFieldName({ groupName, fieldName })
        let value = values[name] ? values[name] : ''

        if (fieldName === 'available_on_market_from') {
            value = !isNaN(Number(value)) ? Number(value) : 0
            value = value || new Date().getFullYear()
        }

        if (fieldName === 'country') {
            value = value ? value : 'Polska'
        }

        return (
            <Dropdown
                {...getFormikProps({ groupName, fieldName })}
                handleChange={value => setFieldValue(name, value)}
                error={
                    errors[name]
                        ? Array.isArray(errors[name])
                            ? errors[name][0]
                            : errors[name]
                        : null
                }
            >
                {items &&
                    items.map(item => {
                        return (
                            <Dropdown.Item
                                key={item}
                                value={item}
                                selected={value === item}
                            >
                                {item}
                            </Dropdown.Item>
                        )
                    })}
            </Dropdown>
        )
    }
}

export default withStyles(styles)(ProductCreatePage)
