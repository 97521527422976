import React from 'react'
import { InputField as InputFieldBase } from '@mindz/react-admin-ui'

import { withStyles }from '@mindz/react-hoc'

import styles from './index.module.css'

export default withStyles(styles)(({ children, ...props }) => {
    const customHandleChange = (value) => {
        if (props.replaceDot) {
            const newValue = value.target.value.replace(',', '.')
            props.setFieldValue(props.name, newValue)
        } else {
            props.handleChange(value)
        }
    }

    const showValue = () => props.replaceDot ? props.value.replace('.', ',') : props.value

    return (
        <InputFieldBase.Kaufland {...props} handleChange={customHandleChange} value={showValue()}>{children}</InputFieldBase.Kaufland>
    )
})